<template>
  <div>
    <a-dropdown
      placement="bottomLeft"
      trigger="hover"
    >
      <div class="px-2 cursor-pointer">
        <a-avatar
          v-if="userStore.user?.user?.avatar"
          :size="46"
          :src="userStore.user?.user?.avatar"
        />
        <span class="ml-2">{{ userStore.user?.company?.name }}</span>
      </div>
      <template #overlay>
        <a-menu @click="handleClickUserMenu">
          <a-menu-item
            v-if="!isIndex"
            key="unsubscribe"
          >
            <div class="flex items-center">
              <user-switch-outlined /> <span class="ml-2">账户注销</span>
            </div>
          </a-menu-item>
          <a-menu-item
            v-if="!isIndex"
            key="companies"
          >
            <div class="flex items-center">
              <basic-icon name="icon-buildings" /> <span class="ml-2">企业管理</span>
            </div>
          </a-menu-item>
          <a-menu-item
            v-if="isIndex"
            key="index"
          >
            <div class="flex items-center">
              <basic-icon name="icon-computer-line" /><span class="ml-2">工作台</span>
            </div>
          </a-menu-item>
          <a-menu-item
            v-else
            key="resetPassword"
          >
            <div class="flex items-center">
              <user-switch-outlined /> <span class="ml-2">修改登录密码</span>
            </div>
          </a-menu-item>
          <!-- <a-menu-divider />
        <a-menu-item key="resetPayPassword">
          <div class="flex items-center">
            <user-switch-outlined /> <span class="ml-2">修改支付密码</span>
          </div>
        </a-menu-item> -->
          <a-menu-divider />
          <a-menu-item key="logout">
            <div class="flex items-center">
              <logout-outlined />
              <span class="ml-2">退出登录</span>
            </div>
          </a-menu-item>
        </a-menu>
      </template>
    </a-dropdown>
    <basic-modal
      v-bind="bindModalAttrs"
      v-model:visible="unsubscribeModalVisible"
    >
      <div class="text-base leading-5">
        <p>很遗憾，欢创背调无法继续为您提供服务，感谢您一直以来的陪伴。</p>
        <p class="font-bold">
          注销后，您将放弃以下权益:
        </p>
        <p>1.您的所有交易记录将被清空，请确保所有交易已完结目无纠纷。</p>
        <p>2.账户注销后因历史交易可能产生的退款、维权相关的资金退回等权益将视作自动放弃</p>
        <p>3.您的身份信息、账户信息、优惠券等权益将被清空，且无法恢复。</p>
        <p>4.您的账户如有充值金额未使用，请先联系客服: <span class="font-bold">18022447450</span>先进行退款处理。</p>
        <p class="font-bold">
          注销时，请您确认:
        </p>
        <p>所有订单已完成且无纠纷</p>
        <p>温馨提示:我们将在7个工作日内为您完成账号注销</p>
        <a-divider />
        <p>请在下方输入框中输入手机号以确认操作：</p>
      </div>
      <a-form
        ref="unsubscribeFormRef"
        :model="unsubscribeFormData"
        :rules="unsubscribeRules"
        style="width: 50%;"
      >
        <a-form-item
          name="mobile"
          required
        >
          <div class="mb-2 font-bold">
            手机号：
          </div>
          <a-input
            v-model:value="unsubscribeFormData.mobile"
            placeholder="请输入手机号"
            class="h-10 rounded-md"
            type="number"
            allow-clear
          />
        </a-form-item>
        <div class="relative">
          <a-form-item
            class="w-4/6 mb-10 auth-code "
            name="captcha"
            default-value="123456"
            required
          >
            <div>
              <div class="mb-2 font-bold">
                验证码：
              </div>
              <a-input
                v-model:value="unsubscribeFormData.captcha"
                type="number"
                allow-clear
                class="h-10 rounded-md"
                placeholder="请输入验证码"
              />
            </div>
          </a-form-item>
          <div>
            <ValidCodeComp
              class="absolute z-10 rounded cursor-pointer right-2 top-[30px]"
              size="large"
              type="primary"
              :mobile="unsubscribeFormData.mobile"
            />
          </div>
          <div v-if="false">
            <a-button
              v-if="captchaCountdown === 0"
              type="primary"
              class="absolute z-10 rounded cursor-pointer right-2"
              style="top: 30px;"
              size="large"

              @click="sendCaptcha"
            >
              获取验证码
            </a-button>
            <a-button
              v-else
              class="absolute z-10 transition-opacity rounded cursor-pointer right-2 disabled-button"
              style="top: 30px;"
              size="large"
              type="primary"
            >
              {{ captchaCountdown }} 秒重新获取
            </a-button>
          </div>
        </div>
      </a-form>
    </basic-modal>
    <basic-modal
      v-bind="CompanyListModalAttrs"
      v-model:visible="companyListModalVisible"
    >
      <a-select
        v-model:value="defaultCompanyId"
        class="w-full"
      >
        <a-select-option
          v-for="item in companyList"
          :key="item.id"
          :value="item.id"
        >
          <div class="flex items-center">
            <p class="text-lg font-semibold ml-1">
              {{ item.name }}
              <a-tag
                v-if="item.isActivate"
                color="green"
              >
                默认
              </a-tag>
            </p>
          </div>
          <p class="pl-2">
            企业管理员：{{ item.applyUser.realname }}
          </p>
        </a-select-option>
      </a-select>
    </basic-modal>
  </div>
</template>

<script lang="ts" setup>
import { Modal } from 'ant-design-vue'
import { authApi } from '@/api/auth'
import { userApi } from '@/api/user'
import { companyApi } from '@/api/company'
import { useUserStore, useAppStore } from '@/store'
import { useCaptcha } from '@/hooks/captcha'
import { Rule } from 'ant-design-vue/es/form'
import { router } from '@/router'
import { BasicModalProps } from '@/components/modal/prop'
import globalModelForm from '@/hooks/global-model-form'
import isMobilePhone from 'validator/es/lib/isMobilePhone'
import { useMessage } from '@/hooks/message'

defineProps({
  isIndex: {
    type: Boolean,
    default: false
  }
})
const userStore = useUserStore()
const appStore = useAppStore()
const { captchaCountdown, getCaptcha } = useCaptcha()
const unsubscribeModalVisible = ref<boolean>(false)
const unsubscribeFormRef = ref<IForm.Expose>()
const unsubscribeFormData = reactive({
  mobile: '',
  captcha: '',
})
const companyListModalVisible = ref<boolean>(false)

interface CompaniesModel{
  id: number,
  name: string,
  createdAt: string,
  auditStatus: number,
  isActivate: number,
  isSelected: boolean,
  applyUser: {
    realname: string
  },
}

const companyList = ref<CompaniesModel[]>([])
const defaultCompanyId = ref(userStore.user?.company?.id)

const unsubscribeRules: Record<string, Rule[]> = {
  mobile: [
    {
      required: true,
      trigger: 'blur',
      validator: (rule: Rule, value: string) => {
        if (!value.trim()) {
          return Promise.reject(new Error('手机号不能为空'))
        }
        if (!isMobilePhone(value.trim(), 'zh-CN')) {
          return Promise.reject(new Error('手机号不正确'))
        }
        return Promise.resolve()
      },
    }
  ],
  captcha: [
    {
      required: true,
      trigger: 'blur',
      validator: (rule: Rule, value: string) => {
        if (!value.trim()) {
          return Promise.reject(new Error('验证码不能为空'))
        }
        if (value.trim().length !== 6) {
          return Promise.reject(new Error('请输入 6 位验证码'))
        }
        return Promise.resolve()
      },
    }
  ],
}

async function handleClickUserMenu({ key }) {
  switch (key) {
    case 'resetPassword':
      // 等待模态框渲染完再清空回填密码
      await globalModelForm.init({
        title: '修改登录密码',
        schemas: [
          // {
          //   label: '真实姓名',
          //   field: 'name',
          //   component: 'Input',
          //   componentProps: {
          //     disabled: true,
          //   },
          //   required: true,
          // } as IForm.Schema<'Input'>,
          {
            label: '登录密码',
            field: 'password',
            component: 'InputPassword',
            componentProps: {
              autocomplete: 'new-password',
            },
            required: true,
            rules: [
              {
                validator: (rule: Rule, value: string) => {
                  if (!value) {
                    return Promise.reject(new Error('密码不能为空'))
                  }
                  if (value.trim().length < 8 || value.trim().length > 15) {
                    return Promise.reject(new Error('密码长度不正确，请输入 8-15 位密码'))
                  }
                  return Promise.resolve()
                }
              }
            ],
          } as IForm.Schema<'InputPassword'>,
          {
            label: '二次确认登录密码',
            field: 'passwordConfirmation',
            component: 'InputPassword',
            required: true,
            componentProps: {
              autocomplete: 'new-password',
            },
            rules: [
              {
                required: true,
                trigger: 'blur',
                validator: (rule: Rule, value: string) => {
                  if (globalModelForm.formModel.password !== value) {
                    return Promise.reject(new Error('二次密码不一致，请重新输入'))
                  }
                  return Promise.resolve()
                },
              }
            ]
          } as IForm.Schema<'InputPassword'>,
          // {
          //   label: '验证码',
          //   field: 'captcha',
          //   component: 'InputCaptcha',
          // } as IForm.Schema<'InputCaptcha'>,
        ],
        model: userStore.user!,
        api: authApi.resetPassword,
        width: 480,
      })
      globalModelForm.formRef.setFormModel({ password: '' })
      break
      // case 'resetPayPassword':
      //   globalModelForm.init({
      //     title: '修改支付密码',
      //     schemas: [
      //       {
      //         label: '真实姓名',
      //         field: 'name',
      //         component: 'Input',
      //         componentProps: {
      //           disabled: true,
      //         },
      //         required: true,
      //       } as IForm.Schema<'Input'>,
      //       {
      //         label: '手机号码',
      //         field: 'mobile',
      //         component: 'Input',
      //         componentProps: {
      //           disabled: true,
      //           autocomplete: 'new-password',
      //         },
      //         required: true,
      //       } as IForm.Schema<'Input'>,
      //       {
      //         label: '支付密码',
      //         field: 'payPassword',
      //         component: 'InputPassword',
      //         componentProps: {
      //           autocomplete: 'new-password',
      //         },
      //         required: true,
      //         rules: [
      //           {
      //             validator: (rule: Rule, value: string) => {
      //               if (!value.trim()) {
      //                 return Promise.reject(new Error('支付密码不能为空'))
      //               }

      //               if (value.trim().length < 8 || value.trim().length > 15) {
      //                 return Promise.reject(new Error('支付密码长度不正确，请输入 8-15 位支付密码'))
      //               }

    //               return Promise.resolve()
    //             }
    //           }
    //         ],
    //       } as IForm.Schema<'InputPassword'>,
    //       {
    //         label: '二次确认支付密码',
    //         field: 'payPasswordConfirmation',
    //         component: 'InputPassword',
    //         componentProps: {
    //           autocomplete: 'false'
    //         },
    //         rules: [
    //           {
    //             required: true,
    //             trigger: 'blur',
    //             validator: (rule: Rule, value: string) => {
    //               if (globalModelForm.formModel.payPassword !== value) {
    //                 return Promise.reject(new Error('二次密码不一致，请重新输入'))
    //               }
    //               return Promise.resolve()
    //             },
    //           }
    //         ]
    //       } as IForm.Schema<'InputPassword'>,
    //       {
    //         label: '验证码',
    //         field: 'captcha',
    //         component: 'InputCaptcha',
    //       } as IForm.Schema<'InputCaptcha'>,
    //     ],
    //     model: userStore.user!,
    //     api: authApi.resetPayPassword,
    //     width: 480,
    //   })
    //   break
    case 'logout':
      Modal.confirm({
        title: '提醒',
        content: '确认退出登录吗？',
        onOk: async () => {
          await userStore.logout(true)
        },
      })
      break
    case 'index':
      appStore.refreshReference()
      userStore.getMenuList()
      router.push('workbench')
      break
    case 'unsubscribe':
      await unsubscribeAccountVerify()
      break
    case 'companies':
      companyListModalVisible.value = true
      break
    default:
      break
  }
}

const getCompanyList = async () => {
  companyList.value = []
  companyList.value = await companyApi.getCompanyList({
    auditStatuses: [appStore.getReferenceConstantMap('companyAuditStatusEnum').SUCCESS.value]
  })
  companyList.value.sort((a, b) => {
    if (a.isActivate && !b.isActivate) {
      return -1
    } else if (!a.isActivate && b.isActivate) {
      return 1
    } else {
      return 0
    }
  })
}

getCompanyList()

const bindModalAttrs = computed((): BasicModalProps => ({
  title: '账号注销',
  width: 900,
  okText: '确定',
  cancelText: '取消',
  destroyOnClose: true,
  onOk: async () => {
    await unsubscribeFormRef.value?.validate()
    unsubscribeModalVisible.value = false
    Modal.confirm({
      title: '温馨提示',
      okText: '确认',
      content: '确认注销当前企业账户？',
      onOk: async () => {
        await unsubscribe()
      }
    })
  },
  onCancel: () => {
    unsubscribeModalVisible.value = false
  }
}))

const CompanyListModalAttrs = computed((): BasicModalProps => ({
  title: '切换企业',
  width: 480,
  okText: '确定',
  cancelText: '取消',
  destroyOnClose: true,
  onOk: async () => {
    try {
      if (defaultCompanyId.value !== userStore.user?.company?.id) {
        const { accessToken } = await authApi.changeCompany({
          activateCompanyId: defaultCompanyId.value
        })
        await userStore.loginSuccess(accessToken)
        location.reload()
      }
    } finally {
      companyListModalVisible.value = false
    }
  },
  onCancel: () => {
    defaultCompanyId.value = userStore.user?.company?.id
    companyListModalVisible.value = false
  }
}))

async function sendCaptcha() {
  if (!isMobilePhone(unsubscribeFormData.mobile.trim(), 'zh-CN')) {
    return useMessage.error('请输入正确的手机号')
  }
  await getCaptcha(unsubscribeFormData.mobile)
}

const unsubscribe = async () => {
  try {
    await userApi.unsubscribe(unsubscribeFormData)
    Modal.success({
      title: '温馨提示',
      content: '我们将在7个工作日内为您完成账号注销',
      okText: '好的',
      onOk: async () => {
        await userStore.logout(true)
      }
    })
  } catch (err) {
    await unsubscribeAccountVerify()
  }
}

const unsubscribeAccountVerify = async () => {
  const res = await userApi.unsubscribeAccountVerify()
  if (!res.canUnsubscribe && res.companyUserCount > 1) {
    Modal.confirm({
      title: '温馨提示',
      okText: '前往解绑',
      content: res.unsubscribeMsg,
      onOk: () => {
        router.push({ name: 'teamAccount' })
      }
    })
  }
  if (!res.canUnsubscribe && res.companyUserCount === 1) {
    Modal.confirm({
      title: '温馨提示',
      content: res.unsubscribeMsg,
    })
  }
  if (res.canUnsubscribe) {
    unsubscribeModalVisible.value = true
  }
}
</script>
